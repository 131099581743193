<template>
  <!--
    The view for the Terminal status
  -->
  <div class="installationDetail_TerminalStatus">
    <Portlet
      title="Terminal"
      icon="tablet-alt"
    >
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <iframe
          v-if="resortId"
          class="iframe"
          :src="`https://admin.skiline.cc/php/alturos/content/admin/id/tool/resortTicketExamples.php?resort=${ resortId }`"
        />
        <p v-else>
          Resort not found.
        </p>
      </template>
    </Portlet>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailTerminalTool",
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      resortId: null
    };
  },
  created () {
    this.getResortId();
  },
  metaInfo () {
    return {
      title: 'Terminal'
    };
  },
  methods: {
    getResortId () {
      this.loading = true;
      this.axios.get(`/Installation/Get?id=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.resortId = response.data.resortId;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.iframe {
  width: 100%;
  height: 100vh;
}
</style>